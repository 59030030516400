import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'
import { ImagePass } from '../../Image/ImagePass'
import { FaChevronRight } from '../../../images/icons/FaChevronRight'
import './PostListItem.scss'
import { decodeEntities } from '../../../utils/helpers'

export default class ListItem extends React.Component {
  render() {
    const { siteMetadata, data, lazy, pathPrefix } = this.props
    const { excerpt, title, featured_media, date, path, slug } = data
    return (
      <div className="post-list-item blog">
        <div>
          <div className="image">
            <GatsbyLink to={path} aria-label={title}>
              <ImagePass
                src={featured_media}
                className="background"
                lazy={lazy}
              />
            </GatsbyLink>
          </div>
          <div className="inner">
            <div className="meta">
              <div>{date && <span className="date">{date}</span>}</div>
            </div>
            <span className="title h5">
              <GatsbyLink to={path} aria-label={decodeEntities(title)}>
                {decodeEntities(title)}
              </GatsbyLink>
            </span>
            {excerpt && <RenderContent content={`${excerpt}`} />}
            {path && (
              <div className="link">
                <GatsbyLink to={path} aria-label={title} className="post-link">
                  {pathPrefix === "blog" ? "View blog" : "View project"}
                </GatsbyLink>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
