import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import PostList from '../components/Posts/PostList'
import { PageHeader } from '../components/PageHeader'
import { decodeEntities } from '../utils/helpers'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const {
      postsOnPage,
      categoryFilter,
      categories,
      site,
      wordpressWpSettings,
      yoast = [],
      siteSettings,
    } = data
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug, description, acf } = pageContext
    const title = `${category}`
    const thisCategory = categories.edges.filter(i => i.node.slug === slug)
    const { newsletterText } = siteSettings.options

    return (
      <>
        <div className="page-wrap">
          <SEO
            title={`${yoast.metaTitle ||
              decodeEntities(category)} | ${decodeEntities(siteTitle)}`}
            yoast={yoast}
            location={location}
          />

          <PageHeader
            headerTitle={acf.title ? acf.title : category}
            headerSubTitle={description}
            location={location}
          />
          <PostList
            posts={posts}
            title={title}
            pageContext={pageContext}
            categoryFilter={categoryFilter}
            siteMetadata={wordpressWpSettings}
            pathPrefix={wordpressWpSettings.blogSlug}
            pathSuffix={`category/${slug}/`}
          />
        </div>
        <NewsletterSignUp content={newsletterText} />
      </>
    )
  }
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        slug: { ne: "gatsby-demo" }
        categories: { elemMatch: { slug: { eq: $slug } } }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
